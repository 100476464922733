<template>
  <div class="steam-wrapper">
    <div class="accelerator">
      <!-- 选择加速地区 -->
      <div class="accelerator-box" v-loading="loading" :element-loading-text="loadingText"
        element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.7)">
        <div class="flex-content">
          <div class="left">
            <div class="top">
              <div class="stem-box">
                <img src="@/assets/img/steam-bg.png" width="100%" alt="" />
              </div>

              <h1>Steam商店/社区</h1>
              <p>Steam社区/商店/个人信息</p>

              <div class="btn-box">
                <el-button class="btn" v-if="accIsStart == 0" @click="startAc" :disabled="disabled">开启加速器</el-button>
                <el-button class="btn" v-else @click="stopAc" :disabled="disabled">切换/关闭加速器</el-button>
                <div class="ps">
                  【Steam社区加速<span>永久免费</span>】
                </div>
              </div>
            </div>

            <div class="bottom" @click="downLoad">
              <img src="@/assets/img/okAcc.png" width="100%" alt="" />
            </div>
          </div>

          <div class="right">
            <div class="info-box">
              <h1><i v-if="accIsStart == 1">【加速中】</i><span>启用Steam社区加速，针对以下功能加速</span></h1>
              <div class="content">
                <el-row :gutter="20">
                  <el-col :span="8">
                    <div class="grid-content">
                      <h3>Steam商店</h3>
                      <p>（解锁访问限制）</p>
                      <p class="icon">√</p>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="grid-content">
                      <h3>Steam好友聊天</h3>
                      <p class="icon">√</p>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="grid-content">
                      <h3>Steam图片</h3>
                      <p class="icon">√</p>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="grid-content">
                      <h3>Steam更新</h3>
                      <p class="icon">√</p>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="grid-content">
                      <h3>Steam讨论/留言</h3>
                      <p>（ipv6）</p>
                      <p class="icon">√</p>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="grid-content">
                      <h3>Steam登录</h3>
                      <p class="icon">√</p>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>

            <!-- 加速社区图片 -->
            <div class="stem-box">
              <img src="@/assets/img/steam_ad.gif" width="512px" height="328px" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cMethods from "@/util/cMethods.js";
import { myMixins } from "@/util/mixins.js";

export default {
  mixins: [myMixins],
  data() {
    return {
      accIsStart: 0,
      disabled: false,
      IPList: [],
      nodeList: [],
      itemObj: null,
      loading: false,
      loadingText: "加速器启动中...",
      selectIndex: 0,
    };
  },
  created() {
    // 获取加速器状态
    this.accIsStart = cMethods.Form_jsControlsNginxAcce(3)
    console.log("NginxAcce是否打开", this.accIsStart)


    this.$statistic(["A", "社区加速", "网页", "访问量"]);
  },
  methods: {
    // 开始加速
    startAc() {
      this.loadingText = "加速器启动中..."
      this.loading = true;
      cMethods.Form_jsControlsNginxAcce(1)

      setTimeout(() => {
        this.loading = false;
        this.accIsStart = cMethods.Form_jsControlsNginxAcce(3)
        console.log("NginxAcce是否打开", this.accIsStart)
      }, 10000);
    },
    // 关闭加速
    stopAc() {
      this.loadingText = "加速器关闭中..."
      this.loading = true;
      cMethods.Form_jsControlsNginxAcce(2)

      setTimeout(() => {
        this.loading = false;
        this.accIsStart = cMethods.Form_jsControlsNginxAcce(3)
        console.log("NginxAcce是否打开", this.accIsStart)
      }, 10000);
    },
    downLoad() {
      cMethods.OpenIeUrl(
        "https://down.haoyouzhushou.com/client/JSQ/OkJiasuqi.exe"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
// @import "@/assets/css/theme.scss";

.steam-wrapper {
  padding: 20px;

  .accelerator {
    .accelerator-box {
      background-size: 100% 100%;

      .flex-content {
        display: flex;

        .left {
          .top {
            width: 300px;
            height: 366px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @include item_bg_col();
            @include font_col3();
            border-radius: 6px;

            h1 {
              margin-top: 15px;
              font-size: 20px;
            }

            p {
              margin-top: 12px;
              font-size: 15px;
            }

            .btn-box {
              margin-top: 20px;

              .btn {
                width: 200px;
                height: 36px;
                margin: 0 auto;
                padding: 0 !important;
                font-size: 15px;
                background: #ff5555;
                color: #fff;
                border: none;
                border-radius: 4px;
                box-sizing: border-box;

                &:hover {
                  background: transparent;
                  border: 1px solid #ff5555;
                  color: #ff5555;
                }

                &.is-disabled {
                  background: #ff5555;
                  color: #000;
                }
              }

              .ps {
                font-size: 16px;
                margin-top: 10px;
                text-align: center;

                span {
                  color: $red_col();
                }
              }
            }
          }

          .bottom {
            margin-top: 20px;
            cursor: pointer;
          }

        }

        .right {
          flex: 1;
          margin-left: 20px;

          .info-box {
            padding: 10px 20px;
            @include item_bg_col();
            border-radius: 6px;

            h1 {
              padding: 10px 0;
              //   background: red;
              border-bottom: 1px solid $border-col;
              font-size: 20px;
              @include font_col3();

              span {
                margin-left: 10px;
                font-size: 16px;
              }
            }

            .content {
              .grid-content {
                height: 66px;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 10px;
                border-bottom: 1px solid $border-col;
                position: relative;
                font-size: 16px;
                line-height: 24px;
                @include font_col9();

                &::before {
                  content: " ";
                  width: 1px;
                  height: 86px;
                  position: absolute;
                  background: $border-col;
                  top: 5px;
                  left: -10px;
                }

                &::after {
                  content: " ";
                  width: 1px;
                  height: 86px;
                  position: absolute;
                  background: $border-col;
                  top: 5px;
                  right: -10px;
                }

                .icon {
                  font-size: 24px;
                  color: $red_col;
                }
              }
            }
          }

          // h1 {
          //   font-size: 24px;
          // }

          // .select-box {
          //   display: flex;
          //   justify-content: space-between;
          //   align-items: center;

          //   .title {
          //     @include font_col3();
          //     font-size: 18px;

          //     span {
          //       font-size: 15px;
          //       margin-left: 20px;
          //       @include font_col9();
          //     }
          //   }
          // }

          // ul {
          //   margin-bottom: 10px;
          //   height: 286px;
          //   overflow: auto;

          //   li {
          //     margin-top: 10px;
          //     height: 48px;
          //     padding: 5px 25px;
          //     display: flex;
          //     justify-content: space-between;
          //     align-items: center;
          //     position: relative;
          //     cursor: pointer;
          //     @include item_bg_col();
          //     border-radius: 6px;

          //     .tj-icon {
          //       position: absolute;
          //       top: 0;
          //       left: 0;
          //     }

          //     .icon-box {
          //       display: flex;
          //       align-items: center;

          //       img {
          //         margin-right: 10px;
          //       }

          //       p {
          //         @include font_col3();
          //         margin-bottom: 5px;
          //       }

          //       span {
          //         @include font_col9();
          //       }
          //     }

          //     .time-box {
          //       display: flex;
          //       align-items: center;
          //       width: 116px;

          //       .el-button {
          //         margin-right: 10px;
          //         color: $red_col;
          //         border: 1px solid $red_col;
          //         background: transparent;
          //       }

          //       span {
          //         color: $red_col;
          //       }
          //     }

          //     &.active {
          //       border: 1px solid $red_col;
          //       @include tag_item_bg_col();
          //       background-position: bottom right;
          //     }
          //   }
          // }

          // ul::-webkit-scrollbar {
          //   width: 4px;
          //   height: 1px;
          //   margin-right: 10px;
          // }

          // ul::-webkit-scrollbar-track {
          //   border-radius: 10px;
          //   background: #fff;
          // }

          // ul::-webkit-scrollbar-thumb {
          //   border-radius: 10px;
          //   background-color: #ff5555;
          // }

          .stem-box {
            margin-top: 20px;
            padding: 15px 100px;
            border-radius: 6px;
            @include item_bg_col();
          }
        }
      }
    }
  }
}
</style>
